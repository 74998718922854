// @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body,
#root {
  height: 100%;
}

button .ant-btn-loading-icon {
  display: none;
}
