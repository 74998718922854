#pro-table-base {
  background-color: #f0f2f5;
}

.pro-table-wrap {
  height: 100%;
  // background-color: #eee;
  // border: 1px solid blue;

  .pro-table-body-wrap {
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 24px;

    .ant-table-cell{
      // text-align: center;
    }
    .expander-content-wrap{
      text-align: left;
    }
  }

  // 工具栏
  .pro-table-tools {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    // padding: 12px 0;
    padding-right: 24px;

    > .pro-table-tools-title {
      display: flex;
      align-items: center;
      margin: 16px 0;

      > span:first-child {
        width: 4px;
        height: 16px;
        background: #d8d8d8 linear-gradient(315deg, #35fcff 0%, #0443fc 100%);
        border-radius: 2px;
        margin-right: 10px;
      }

      > span:last-child {
        font-size: 18px;
        font-weight: 600;
        color: #000000;
        line-height: 24px;
      }
    }
    // 工具栏actions
    .pro-table-tools-actions-wrap {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      button {
        margin: 12px 5px;
        border-radius: 4px;
        // padding: 6px 13px;
      }

      .ant-radio-group {
        margin: 12px 5px;
      }

      > div:last-child button {
        margin-right: 0;
      }
    }
  }

  > .ant-table-wrapper {
    border-radius: 4px;
    overflow: hidden;
  }

  .ant-table-pagination {
    background: #fff;
    margin: 0;
    padding: 16px;
    margin-top: 0!important;
  }
}
