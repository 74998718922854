.layout-header-wrap {
  height: 48px;
  padding: 18px;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  position: relative;
  z-index: 10;

  .layout-header-right {
    > span {
      padding: 0 10px;
    }
    .userinfo {
      margin-right: 10px;
      img:first-child {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        //    font-size: 24px;
        margin: 0 10px;
      }

      span {
        margin-left: 5px;
      }
      .ant-dropdown-link {
        display: flex;
        align-items: center;
      }
    }
    > div:last-child {
      margin-left: 30px;
    }
  }

  .poweroffOutlined {
    svg {
      color: orange;
    }
  }
}
