.layout-wrap {
  height: 100%;

  > aside {
    overflow-y: auto;
  }

  .logo {
    height: 70px;
    display: flex;
    img {
      transition: all .3s;
      margin: auto;
      // width: 70%;
    }
  }

  // 左侧菜单
  .ant-layout-sider,
  .ant-menu,
  .ant-menu-dark,
  .ant-menu-dark,
  .ant-menu-inline.ant-menu-sub {
    background-color: #213262;
  }
  .ant-menu-item-selected {

    // background:transparent!important;
    // background: #1890ff linear-gradient(315deg, #35fcff 0%, #0443fc 100%)!important;
  }

  .ant-layout-content {
    min-height: auto;
    // height: 100vh;
  }

  .ant-menu.ant-menu-dark {
    background-color: transparent;
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: transparent;
  }

  // 主要区域
  .site-layout {
    background-color: #f0f2f5;
    flex: 1;
    .layout-conent {
      // background-color: #fff;

      > div {
        margin: 24px 16px;
        // height: 100%;
        width: revert;
        // overflow-x: auto;
      }
    }
  }
}
